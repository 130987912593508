import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import {
  getDtMoodSession,
  removeDtMoodSession,
} from "../modules/auth/services";
import { ChangeAuthentication } from "../modules/auth/store/authentication/AuthenticationSlice";

export const useTokenDtMood = () => {
  const dispatch = useDispatch();

  const mostrarAlertaDtMood = () => {
    Swal.fire({
      title: "Token DtMood vencido",
      text: "Debes iniciar sesión de nuevo en DtMood",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Cerrar sesión",
      allowEscapeKey: false,
      allowOutsideClick: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        removeDtMoodSession();
        dispatch(ChangeAuthentication(undefined));
      }
    });
  };

  const validarTokenDtMood = () => {
    const dtMoodSession = getDtMoodSession();
    if (!dtMoodSession) {
      removeDtMoodSession();
      dispatch(ChangeAuthentication(undefined));
      return;
    }

    const today = new Date();
    const validToken = today > dtMoodSession.time_expire;

    if (validToken) {
      mostrarAlertaDtMood();
    }
  };

  useEffect(() => {
    validarTokenDtMood();
  }, []);

  return { validarTokenDtMood };
};
