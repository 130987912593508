import { useEffect, useState, useContext } from "react";
import { Button, Textarea } from "@fluentui/react-components";
import { CheckmarkFilled } from "@fluentui/react-icons";
import { Form, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import { ChangeMiDia } from "../../../store/mi-dia/MiDiaSlice";
import { EncuestaContext } from "../../../context";
import useApi from "../../../../../hooks/useApi";
import styles from "./FormularioEncuesta.module.css";
import { responseValues } from "./response-values";

import emocion1 from "../../../../../assets/images/dt-mood/encuestas/1.svg";
import emocion2 from "../../../../../assets/images/dt-mood/encuestas/2.svg";
import emocion3 from "../../../../../assets/images/dt-mood/encuestas/3.svg";
import emocion4 from "../../../../../assets/images/dt-mood/encuestas/4.svg";
import emocion5 from "../../../../../assets/images/dt-mood/encuestas/5.svg";

const FormularioEncuesta = (props) => {
  const { encuesta } = props;
  const { data: detalleEncuesta, getData } = useApi();
  const {
    data: dataPost,
    loading: loadingPost,
    error: errorPost,
    postData,
  } = useApi();
  const values = JSON.parse(JSON.stringify(responseValues));
  const emocioneValues = JSON.parse(JSON.stringify(responseValues));
  const [calificaciones, setCalificaciones] = useState(values);
  const [emociones, setEmociones] = useState(emocioneValues);
  const [calificacionesSeleccionadas, setCalificacionesSeleccionadas] =
    useState();
  const [emocionesSeleccionadas, setEmocionesSeleccionadas] = useState();
  const [clicked, setClicked] = useState(false);
  const { setEncuestaState } = useContext(EncuestaContext);
  const dispatch = useDispatch();
  const detalleENcuestaUrl = `/api/sresults/${encuesta.id}/detail/`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    reValidateMode: "onChange",
  });

  useEffect(() => {
    getData(detalleENcuestaUrl);
    setCalificaciones(values);
    setEmociones(emocioneValues);
    return () => {
      setCalificacionesSeleccionadas();
      setEmocionesSeleccionadas();
      setClicked(false);
      cleanSelection();
    };
  }, []);

  useEffect(() => {
    watch((value, { name, type }) => {});
  }, [watch]);

  useEffect(() => {
    if (dataPost) {
      const state = dataPost.award.id === "" ? "success" : "award";
      setEncuestaState({
        state,
        badge_id: dataPost.award.badge_id,
      });
      dispatch(ChangeMiDia(false));
    }

    if (errorPost) {
      console.log(errorPost);
      setEncuestaState({
        state: "warning",
      });
    }
  }, [dataPost, errorPost]);

  const handleClick = (index) => {
    cleanSelection();
    calificaciones[index].selected = true;
    setCalificacionesSeleccionadas(calificaciones[index]);
  };

  const handleClickEmociones = (index) => {
    cleanSelectionEmociones();
    emociones[index].selected = true;
    setEmocionesSeleccionadas(emociones[index]);
  };

  const cleanSelection = () => {
    calificaciones.map((item) => (item.selected = false));
    setCalificacionesSeleccionadas();
  };

  const cleanSelectionEmociones = () => {
    emociones.map((item) => (item.selected = false));
    setEmocionesSeleccionadas();
  };

  const onSubmit = (data) => {
    setClicked(true);
    if (
      !calificacionesSeleccionadas ||
      calificacionesSeleccionadas.length === 0
    )
      return;
    if (!emocionesSeleccionadas || emocionesSeleccionadas.length === 0) return;

    const formData = formatData(data);
    postData(`/api/sresults/update/`, formData);
  };

  const formatData = (data) => {
    const nuevaEncuesta = { ...detalleEncuesta };
    nuevaEncuesta.questions[0].score = calificacionesSeleccionadas.value;
    nuevaEncuesta.questions[0].feel = emocionesSeleccionadas.value;
    nuevaEncuesta.questions[0].comment = data.comments;

    return nuevaEncuesta;
  };

  const getImagenEmocion = (value) => {
    switch (value) {
      case 1:
        return emocion1;

      case 2:
        return emocion2;

      case 3:
        return emocion3;

      case 4:
        return emocion4;

      case 5:
        return emocion5;

      default:
        return emocion1;
    }
  };

  return (
    <>
      <div className="ps-3 pe-3">
        <p className="font-16 font-medium text-center mb-3 ms-2 me-2 mt-2 text-dark-dtmood">
          {detalleEncuesta && detalleEncuesta.description}
        </p>
      </div>

      <div className="ps-3 pe-3">
        <p className="font-16 text-center mb-1 ms-2 me-2 mt-4 text-gray-dtmood">
          Califica tu satisfacción
        </p>
        <p className="font-14 font-medium text-center mb-3 ms-2 me-2 mt-2 text-dark-dtmood">
          ¿Cómo te sentiste?
        </p>

        <div className="d-flex flex-row justify-content-center mb-3">
          {emociones &&
            emociones.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`d-flex justify-content-center align-items-start me-1 cursor-pointer ${
                    item.selected ? styles.emocionSelected : ""
                  }`}
                  onClick={() => handleClickEmociones(index)}
                >
                  <img
                    src={getImagenEmocion(item.value)}
                    alt={item.value}
                    className={styles.iconoEmocion}
                  />
                </div>
              );
            })}
        </div>

        <p className="font-16 text-center mb-1 ms-2 me-2 mt-4 text-gray-dtmood">
          Califica del 1 al 5
        </p>
        <p className="font-14 font-medium text-center mb-3 ms-2 me-2 mt-2 text-dark-dtmood">
          {(detalleEncuesta && detalleEncuesta.questions[0].question) || ""}
        </p>

        <div className="d-flex flex-row justify-content-center mb-3">
          {calificaciones &&
            calificaciones.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`d-flex justify-content-center align-items-start me-1 cursor-pointer ${
                    item.selected ? styles.valueCardSelected : styles.valueCard
                  }`}
                  onClick={() => handleClick(index)}
                >
                  {item.selected && (
                    <div
                      className={`d-flex align-items-center justify-content-center ${styles.itemWraper}`}
                    >
                      <CheckmarkFilled className={styles.checkIcon} />
                    </div>
                  )}
                  {!item.selected && (
                    <div
                      className={`d-flex align-items-center justify-content-center ${styles.itemWraper}`}
                    >
                      {item.value}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div className="text-danger mt-0 mb-3">
          {(!calificacionesSeleccionadas ||
            calificacionesSeleccionadas.length === 0) &&
            clicked &&
            "Selecciona una calificación"}
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup className="w-100">
            <Textarea
              name="comments"
              id="comments"
              cols="30"
              rows="3"
              placeholder="Escribe tu comentario"
              {...register("comments", { maxLength: 300 })}
              className="mb-1 w-100"
            />
          </FormGroup>
          <div className="text-danger mt-0">
            {errors.comments &&
              errors.comments.type === "maxLength" &&
              "Máximo 300 caracteres"}
          </div>

          <FormGroup className="text-center mt-4 mb-5">
            <div className="d-flex align-items-center justify-content-center">
              <Button
                appearance="primary"
                type="submit"
                disabled={loadingPost}
                className="w-50"
              >
                Enviar encuesta
              </Button>
            </div>
          </FormGroup>
        </Form>
      </div>
    </>
  );
};
export default FormularioEncuesta;
